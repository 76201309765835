import { toggleMenu } from "./header";
import { scrollToHash, getSamePageAnchor } from "./smooth-scrolling";
import '../css/main.css';


document.addEventListener("DOMContentLoaded", () => {
  /**
   * Toggle del header
   */
  toggleMenu()

  /**
   * Example carousel import
   */
  const carousel = document.querySelectorAll(".carousel");
  if (carousel.length > 0) {
    import("./carousel").then((module) => {
      carousel.forEach((slider) => {
        module.carousel(slider);
      });
    });
  }

  /**
   * Smooth scrolling support
   */
  // If a link's href is within the current page, scroll to it instead
  document.querySelectorAll('a[href]').forEach(a => {
    a.addEventListener('click', e => {
      scrollToHash(getSamePageAnchor(a), e);
    });
  });
  // Scroll to the element in the URL's hash on load
  scrollToHash(window.location.hash);

  /**
   * ACF7 event listener
   */
  document.addEventListener('wpcf7mailsent', (event) => {
    gtag('event', 'Formulario', {
      'event_category': event.detail.contactFormId,
      'event_label': event.detail.unitTag
    });
  }, false);

  const accordions = document.querySelectorAll(".accordion");
  if (accordions.length > 0) {
    import("./accordions").then((module) => {
      accordions.forEach((accordion) => {
        module.handleAccordion(accordion);
      });
    });
  }

  const viewMore = document.querySelector("#view-more");
  if (viewMore) {
    viewMore.addEventListener("click", () => {
      if (viewMore.classList.contains('toggled')) {
        accordions.forEach((accordion) => {
          if (accordion.dataset.count > 4) {
            accordion.classList.add("hidden");
          }
          viewMore.classList.remove('toggled');
          viewMore.innerText = 'VER MÁS';
        });
      } else {
        accordions.forEach((accordion) => {
          accordion.classList.remove("hidden");
        });
        viewMore.classList.add('toggled');
        viewMore.innerText = 'VER MENOS';
      }
    });
  }
});



